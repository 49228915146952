var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-page" }, [
    _c("section", { staticClass: "form-box reg-form login-form" }, [
      _vm._m(0),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _c(
        "form",
        {
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.login()
            }
          }
        },
        [
          _c("div", { staticClass: "content-form" }, [
            _c("div", { staticClass: "form-row form-fullw" }),
            _vm._v(" "),
            _c("div", { staticClass: "form-row form-fullw" }, [
              _c("div", {}, [
                _c("label", [_vm._v("User name")]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.email,
                      expression: "email"
                    }
                  ],
                  attrs: { type: "text" },
                  domProps: { value: _vm.email },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.email = $event.target.value
                    }
                  }
                })
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-row form-fullw" }, [
              _c("div", { staticClass: "filledx" }, [
                _c("label", [_vm._v("Password")]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.password,
                      expression: "password"
                    }
                  ],
                  attrs: { type: "password" },
                  domProps: { value: _vm.password },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.password = $event.target.value
                    }
                  }
                })
              ])
            ]),
            _vm._v(" "),
            _vm._m(2),
            _vm._v(" "),
            _vm._m(3)
          ])
        ]
      )
    ]),
    _vm._v(" "),
    _vm._m(4)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h1", { staticClass: "logo" }, [
      _c("img", { attrs: { src: "assets/img/logo-andco.png", alt: "" } })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", [_c("span", [_vm._v("CONNEX")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-row form-center" }, [
      _c("div", {}, [_c("button", [_vm._v("Login")])])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-row form-fullw" }, [
      _c("div", { staticClass: "txtc" }, [
        _c("p", [
          _vm._v("Forgot password? "),
          _c("a", { attrs: { href: "" } }, [_vm._v("Click here")])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "form-foot" }, [
      _c("div", { staticClass: "ff-cont" }, [
        _c("p", [
          _vm._v("Visit our Homepage for more information on "),
          _c("a", { attrs: { href: "http://pluggco.com/" } }, [
            _vm._v("Plugg & Co.")
          ]),
          _vm._v(" services.")
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v("Trouble logging in? Please contact: "),
          _c("a", { attrs: { href: "" } }, [_vm._v("support@pluggco.com")])
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "Use of this site is restricted for the use of Plugg & Co. Clients & Associates."
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "ff-logo" }, [
        _c("p", { staticClass: "system-status" }, [
          _vm._v("v1.0.1 "),
          _c("br"),
          _c(
            "a",
            { attrs: { href: "https://status.plgin.net/", target: "_blank" } },
            [_vm._v("System Status")]
          )
        ]),
        _vm._v(" "),
        _c("i")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }